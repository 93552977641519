<template>
  <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">         
    <div class="container mt-5" id="custom-cards">
      <h3 class="pb-2 border-bottom text-dark-blue">Reports</h3>        
      <div class="row row-cols-1 row-cols-md-3 mb-3 text-center">  
        <div class="col mb-3">
          <router-link to="report/time">
            <div class="rounded align-items-center h-30 shadow p-3 bg-body card-body">
              <h6 class="text-start card-title "><i class="icon-color far fa-clock"></i> Time and Activity</h6>
              <p class="card-text text-start mb-2">
                See time worked, activity levels, and amounts earned per project or to-do.
              </p> 
            </div> 
          </router-link>                             
        </div>
        <div class="col mb-3">
          <router-link to="report/weekly">
            <div class="rounded align-items-center h-30 shadow p-3 bg-body card-body">
              <h6 class="text-start card-title "><i class="icon-color fas fa-calendar-week"></i> Weekly</h6>
              <p class="card-text text-start mb-2">
                See time worked, activity levels, and amount earned per week.
              </p> 
            </div>
          </router-link>                                       
        </div>
        <div class="col mb-3">
          <router-link to="report/appurl">
            <div class="rounded align-items-center h-30 shadow p-3 bg-body card-body">
              <h6 class="text-start card-title "><i class="icon-color far fa-window-restore"></i> Apps & Url</h6>
              <p class="card-text text-start mb-2">
                See apps used and URLs visited while working.
              </p> 
            </div> 
          </router-link>                                       
        </div> 
        <div class="col mb-3">
          <router-link to="report/payments">
            <div class="rounded align-items-center h-30 shadow p-3 bg-body card-body">
              <h6 class="text-start card-title "><i class="icon-color far fa-money-bill-alt"></i> Payments</h6>
              <p class="card-text text-start mb-2">
                See the Payments report shows a list of all payments made within the organization.
              </p> 
            </div>
          </router-link>                                        
        </div>
        <div class="col mb-3">
          <router-link to="report/amounts">
            <div class="rounded align-items-center h-30 shadow p-3 bg-body card-body">
              <h6 class="text-start card-title "><i class="icon-color far fa-money-bill-alt"></i> Amounts Owend</h6>
              <p class="card-text text-start mb-2">
                See the Payments report shows a list of all payments not yet made by the organization.
              </p> 
            </div> 
          </router-link>                                       
        </div> 
      </div> 
    </div>
  </main>
</template>

<script>
  export default
  {
    name: 'Report'   
  }
</script>

<style scoped>
  a{
    color:#0D3E67;
  }
</style>
